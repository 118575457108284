
                export function Clouds() {
    return(    <>
            <table className="table table-striped gy-7 gs-7">
            <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                    <th>Name</th>
                    <th>Vendor</th>
                    <th>Vulnerabilities</th>
                    <th>Last Scan</th>
                    <th>Next Scan</th>
                    <th>Reports</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>[AWS-ID]</td>
                    <td>AWS</td>
                    <td>57</td>
                    <td>JAN-23-2023</td>
                    <td>JAN-30-2023</td>
                    <td>[LINK-TO-REPORTS]</td>
                </tr>
                <tr>
                    <td>[AZURE-ID]</td>
                    <td>Azure</td>
                    <td>90</td>
                    <td>JAN-23-2023</td>
                    <td>JAN-30-2023</td>
                    <td>[LINK-TO-REPORTS]</td>
                </tr>
            </tbody>
            </table>
        </>
    )
}
